<template>
  <div>
    <a-modal
      v-model="visibleModalManagePMA"
      class="mod"
      :title="status.isEdit ? 'Form Edit Upper Management' : 'Form Create Upper Management'"
      @cancel="toggleModalManagePMA"
      centered
    >
      <form-upper-management :dataPMA="newPMA" @handle-change="handleChange"/>
      <template slot="footer">
        <a-button size="large" key="back" @click="toggleModalManagePMA">
          Cancel
        </a-button>
        <a-button
          size="large"
          key="submit"
          type="primary"
          :loading="loadingActionModalManagePMA"
          @click="handleOkModalManagePMA"
        >
          {{ status.isEdit ? "Edit" : "Create" }} Now
        </a-button>
      </template>
    </a-modal>
    <div class="d-flex align-items center">
      <div>
        <a-select style="min-width: 250px;" size="large" @change="fetchDataPMA" v-model="jabatan">
          <a-select-option value="all">All Data</a-select-option>
          <a-select-option value="kepsek">Kepala Sekolah</a-select-option>
          <a-select-option value="wakasek">Wakil Kepala Sekolah</a-select-option>
        </a-select>
      </div>
      <div class="ml-auto">
        <a-button
          @click.prevent="toggleModalManagePMA('add')"
          size="large"
          type="primary"
          ><a-icon type="plus" />Create Upper Management</a-button
        >
      </div>
    </div>
    <div class="mt-4">
      <a-table
        :columns="pmaColumns"
        :data-source="pmaDataTable"
        :pagination="pmaPagination"
        :loading="pmaLoadingTable"
        @change="handleTableChange"
        bordered
      >
        <div slot="jabatan" slot-scope="value">
          <a-tag :color="value === 'kepsek' ? 'blue' : 'cyan'">
            {{ value === 'kepsek' ? 'Kepala Sekolah' : value === 'wakasek' ? 'Wakil Kepala Sekolah' : value }}
          </a-tag>
        </div>
        <div slot="status" slot-scope="value">
          <a-tag :color="value === 'active' ? 'green' : 'red'">
            {{ value }}
          </a-tag>
        </div>
        <div slot="divisi" slot-scope="value">{{ value === 'kurikulum' ? 'Kurikulum' : value === 'kesiswaan' ? 'Kesiswaan' : value || '-'}}</div>
        <div slot="nullStr" slot-scope="value">{{value || '-'}}</div>
        <div slot="action" slot-scope="value, record">
          <a-button
            @click.prevent="toggleModalManagePMA('edit', record)"
            class="mb-3 text-warning border border-warning"
            size="large"
            icon="edit"
            block
          >
          Edit
          </a-button>
          <a-button
            @click.prevent="handleDeletePMA(record)"
            class="text-danger border border-danger"
            size="large"
            icon="delete"
            block
          >
          Delete
          </a-button>
        </div>
      </a-table>
    </div>
  </div>
</template>

<script>
import moment from 'moment'
const formUpperManagement = () => import('@/components/app/Admin/Master/Form/UpperManagement')

const pmaColumns = [
  {
    title: 'Teacher Name',
    dataIndex: 'namaGuru',
    key: 'idGuru',
    align: 'center',
    scopedSlots: { customRender: 'namaGuru' },
  },
  {
    title: 'Occupation',
    dataIndex: 'jabatan',
    key: 'jabatan',
    align: 'center',
    scopedSlots: { customRender: 'jabatan' },
  },
  {
    title: 'Division',
    dataIndex: 'bagian',
    key: 'bagian',
    align: 'center',
    scopedSlots: { customRender: 'divisi' },

  },
  {
    title: 'Status',
    dataIndex: 'status',
    key: 'status',
    align: 'center',
    scopedSlots: { customRender: 'status' },
  },
  {
    title: 'Start Occupation',
    dataIndex: 'mulaiMenjabat',
    key: 'mulaiMenjabat',
    align: 'center',
    scopedSlots: { customRender: 'nullStr' },
  },
  {
    title: 'End Occupation',
    dataIndex: 'selesaiMenjabat',
    key: 'selesaiMenjabat',
    align: 'center',
    scopedSlots: { customRender: 'nullStr' },
  },
  {
    title: 'Action',
    dataIndex: 'action',
    key: 'action',
    scopedSlots: { customRender: 'action' },
    align: 'center',
  },
]
export default {
  name: 'PMA',
  components: {
    formUpperManagement,
  },
  data() {
    return {
      pmaColumns,
      visibleModalManagePMA: false,
      loadingActionModalManagePMA: false,
      loadingDeletePMA: false,
      pmaLoadingTable: false,
      pmaDataTable: [],
      pmaPagination: {},
      status: {
        isEdit: false,
        isCreate: false,
      },
      newPMA: {
        idGuru: null,
        jabatan: null,
        bagian: null,
        status: null,
        mulaiMenjabat: null,
        selesaiMenjabat: null,
      },
      idEdit: null,
      jabatan: 'all',
    }
  },
  methods: {
    handleChange(payload) {
      // console.log('handleChange', payload)
      const { value, column } = payload
      if (Array.isArray(column)) {
        column.forEach((col, i) => {
          this.newPMA[col] = value[i]
        })
      } else {
        this.newPMA[column] = value
        if (column === 'jabatan' && value === 'kepsek') this.newPMA.bagian = null
        if (column === 'status' && value === 'active') this.newPMA.selesaiMenjabat = null
      }
    },
    toggleModalManagePMA(status, data) {
      this.visibleModalManagePMA = !this.visibleModalManagePMA
      if (!this.visibleModalManagePMA) {
        setTimeout(() => {
          this.status = {
            isEdit: false,
            isCreate: false,
          }
          this.newPMA = {
            idGuru: null,
            jabatan: null,
            bagian: null,
            status: null,
            mulaiMenjabat: null,
            selesaiMenjabat: null,
          }
          this.idEdit = null
        }, 500)
        return ''
      }

      if (status) {
        this.status[status === 'add' ? 'isCreate' : 'isEdit'] = true
        if (status === 'edit') {
          this.idEdit = data.key
          console.log(data)
          this.newPMA = {
            idGuru: data.idGuru,
            jabatan: data.jabatan,
            bagian: data.bagian,
            status: data.status,
            mulaiMenjabat: data.mulai_menjabat,
            selesaiMenjabat: data.selesai_menjabat,
          }
        }
      }
    },
    handleOkModalManagePMA() {
      const content = `${this.status.isEdit ? 'Are you sure want to edit this upper managament?' : 'Are you sure want to create new upper managament'}`
      if (this.isValidateForm) {
        this.$notification.error({
          message: 'Warning',
          description: 'All fields are required',
        })
      } else {
        this.$confirm({
          title: 'Warning',
          content: (
            <div>{content}</div>
          ),
          onOk: () => {
            // console.log('this.newPMA', this.newPMA)
            // const dataPMA = this.newPMA
            // const dataTable = this.pmaDataTable
            // let isDuplicate = false
            // let teacherName = null
            // for (let i = 0; i < dataTable.length; i++) {
            //   if (dataTable[i].idGuru === dataPMA.idGuru && dataTable[i].status === 'active' && dataPMA.status === 'active' && dataTable[i].jabatan === dataPMA.jabatan) {
            //     isDuplicate = true
            //     teacherName = dataTable[i].namaGuru
            //     break
            //   }
            // }
            // if (isDuplicate === true) {
            //   this.loadingActionModalManagePMA = false
            //   this.toggleModalManagePMA()
            //   this.$notification.error({
            //     message: 'ERROR',
            //     description: `${teacherName} already assigned as active ${dataPMA.jabatan}! Please edit the active data instead making the new one. Or you can inactive the old data first and then make the new active data. `,
            //   })
            // } else {
            this.loadingActionModalManagePMA = true
            const payload = this.status.isEdit ? {
              id: this.idEdit,
              dataPMA: this.newPMA,
            } : {
              dataPMA: this.newPMA,
            }
            this.$store.dispatch(`master/${this.status.isEdit ? 'EDIT' : 'POST'}_PMA`, payload)
              .then(isSuccess => {
                if (isSuccess) {
                  this.loadingActionModalManagePMA = false
                  this.toggleModalManagePMA()
                  this.fetchDataPMA()
                  const description = this.status.isEdit ? 'Upper Management has been updated' : 'Upper Management has been created'
                  this.$notification.success({
                    message: 'Success',
                    description,
                  })
                } else {
                  this.loadingActionModalManagePMA = false
                  this.toggleModalManagePMA()
                  this.fetchDataPMA()
                  const description = this.status.isEdit ? 'Upper Management has not been updated' : 'Upper Management has not been created'
                  this.$notification.error({
                    message: 'Failed',
                    description,
                  })
                }
              })
            // }
          },
          onCancel: () => {
          },
          centered: true,
          icon: 'warning',
          okType: 'primary',
          okText: this.status.isEdit ? 'Edit Now' : 'Create Now',
          cancelText: 'Cancel',
        })
      }
    },
    handleDeletePMA(data) {
      this.$confirm({
        title: 'Warning',
        content: (
          <div>Are you sure want to delete this study pma?</div>
        ),
        onOk: () => {
          this.pmaLoadingTable = true
          this.loadingDeletePMA = true
          this.$store.dispatch('master/DELETE_PMA', {
            id: data.key,
          })
            .then(isSuccess => {
              this.fetchDataPMA()
              this.loadingDeletePMA = false
              if (isSuccess) {
                this.$notification.success({
                  message: 'Success',
                  description:
                    'Upper Management has been deleted',
                })
              } else {
                this.$notification.error({
                  message: 'Failed',
                  description: 'Upper Management has not been deleted',
                })
              }
            })
        },
        onCancel: () => {
          this.loadingDeletePMA = false
        },
        centered: true,
        icon: 'warning',
        okType: 'danger',
        okText: 'Delete',
        cancelText: 'Cancel',
      })
    },
    fetchDataPMA(params = { page: 1, order: 'ASC', search: '', type: '', sortBy: '' }) {
      this.pmaLoadingTable = true
      this.$store.dispatch('master/FETCH_ALL_PMA', { page: params.page, order: params.order, search: params.search, sortBy: params.sortBy, jabatan: this.jabatan })
        .then(res => {
          // console.log('fetchDataPMA res => ', res)
          this.pmaLoadingTable = false
          const pagination = { ...this.pmaPagination }
          pagination.total = res.total
          this.pmaPagination = pagination
          this.pmaDataTable = res.data.map(el => {
            return {
              ...el,
              key: el.id,
              idGuru: el.id_guru,
              namaGuru: el.guru.nama,
              jabatan: el.jabatan,
              bagian: el.bagian,
              status: el.status,
              mulaiMenjabat: el.mulai_menjabat ? moment(el.mulai_menjabat, 'YYYY-MM-DD').format('DD MMM YYYY') : null,
              selesaiMenjabat: el.selesai_menjabat ? moment(el.selesai_menjabat, 'YYYY-MM-DD').format('DD MMM YYYY') : null,
            }
          })
        })
    },
    handleTableChange(pagination, filters, sorter) {
      const pager = { ...this.pmaPagination }
      let order = ''
      pager.current = pagination.current
      this.pmaPagination = pager
      if (sorter.order === 'ascend') {
        order = 'ASC'
      } else if (sorter.order === 'descend') {
        order = 'DESC'
      } else {
        order = 'ASC'
      }
      this.fetchDataPMA({
        page: pagination.current,
        order: order,
      })
    },
  },
  mounted() {
    this.fetchDataPMA()
  },
  computed: {
    isValidateForm() {
      if (this.newPMA.idGuru || this.newPMA.jabatan || this.newPMA.status) {
        return false
      }
      return true
    },
  },
}
</script>

<style>
</style>
